import * as React from "react"
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap"
import ContentLoader from "react-content-loader"
import * as styles from "./LoadingPage.module.css"
import * as headerStyles from "../Header.module.css"
import logoLight from "../../assets/images/logo.png"

const LoadingHeader = () => {
  return (
    <Navbar expand="lg" className={headerStyles.navbar}>
      <Container>
        <Navbar.Brand className={headerStyles.navbarBrand}>
          <img src={logoLight} alt="Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link>
              <ContentLoader viewBox="0 0 200 10" backgroundColor="#dfdfdf" foregroundColor="#cccccc">
                <rect x="0" y="0" rx="4" ry="4" width="50" height="10" />
              </ContentLoader>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const LoadingPageOnly = () => {
  return (
    <Container className={styles.section}>
      <Row>
        <Col md="12">
          <h1>
            <ContentLoader viewBox="0 0 1000 40" backgroundColor="#dfdfdf" foregroundColor="#cccccc">
              <rect x="0" y="0" rx="4" ry="4" width="20%" height="30" />
            </ContentLoader>
          </h1>

          <ContentLoader viewBox="0 0 1000 208" backgroundColor="#dfdfdf" foregroundColor="#cccccc">
            <rect x="0" y="0" rx="4" ry="4" width="60%" height="20" />

            <rect x="0" y="30" rx="4" ry="4" width="77%" height="100" />
          </ContentLoader>
        </Col>
      </Row>
    </Container>
  )
}

const LoadingPage = () => {
  return (
    <>
      <LoadingHeader />
      <LoadingPageOnly />
    </>
  )
}

export { LoadingHeader }
export { LoadingPageOnly }
export default LoadingPage
