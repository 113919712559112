import { navigate } from "gatsby"
import React, { ComponentType, useEffect, FC, useState } from "react"
import { isInt8Array } from "util/types"
import LoadingPage from "../components/auth/LoadingPage"
import log from "../components/log"
import useAuth from "./useAuth"

const withAuth = <P extends object>(Component: ComponentType<P>): any => {
  return function withAuth(props: P): JSX.Element {
    const { isAuthenticated, isLoading } = useAuth()

    function redirectToHomepage() {
      log('debug', '🍪 Not authenticated, redirecting to homepage...')
      navigate("/")
      return <LoadingPage />
    }

    // React.useEffect(() => {
    //   console.log(isAuthenticated)
    // }, [])

    if (isLoading) {
      return <LoadingPage />
    } else {
      // return isAuthenticated ? <LoadingPage /> : redirectToHomepage();
      return isAuthenticated ? <Component {...props} /> : redirectToHomepage()
    }
  }
}

export default withAuth
